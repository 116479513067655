.highlights-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.highlights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.highlights-title {
  font-size: 2.4rem !important;
  font-weight: 600;
  color: #333;
  position: relative;
  margin-bottom: 20px;
  display: block;
}

.highlights-title::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
  background-color: #609041;
}

.highlights-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  overflow-x: auto;
  max-width: 90%;
  flex-wrap: wrap;
}

.highlights-card {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.highlights-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}

.highlights-card img {
  width: 190px;
  height: 285px;
  
  border-radius: 8px;
}

.highlights-card-link {
  position: relative;
  display: block;
}

.highlights-card-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  margin: 10px 0;
  line-height: 1.2;
  text-align: center;
}

.highlights-card-category {
  font-size: 1.2rem;
  color: #333;
  margin-top: 5px;
  text-align: center;
  font-style: italic;
}

.highlights-no-products {
  font-size: 18px;
  color: #609041;
  text-align: center;
}

.highlights-loading {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.highlights-ball-loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid #609041;
  border-top: 3px solid transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .highlights-title {
      font-size: 1.6rem !important;
  }

  .highlights-card-title {
      font-size: 1rem !important;
  }

  .highlights-card-category {
      font-size: 0.9rem !important;
  }

  .highlights-list {
      gap: 20px;
  }

  .highlights-card {
      width: 200px;
  }
}

@media (min-width: 1023px) and (max-width: 1439px) {
  .highlights-title {
      font-size: 2.2rem !important;
  }

  .highlights-card-title {
      font-size: 1.4rem !important; 
  }

  .highlights-card-category {
      font-size: 1.3rem !important;
  }

  .highlights-list {
      gap: 25px;
  }

  .highlights-card {
      width: 210px;
  }
}

@media (min-width: 1440px) and (max-width: 1500px) {
  .highlights-container {
      padding: 40px 0;
  }

  .highlights-title {
      font-size: 2.4rem !important;
  }

  .highlights-card-title {
      font-size: 1.6rem !important; 
  }

  .highlights-card-category {
      font-size: 1.3rem !important;
  }

  .highlights-list {
      gap: 30px;
  }

  .highlights-card {
      width: 230px;
  }
}