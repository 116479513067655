.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f4f4f4, #e8e8e8);
    font-family: 'Poppins', sans-serif;
}

.not-found-content {
    text-align: center;
    background: white;
    padding: 40px 60px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
}

.not-found-title {
    font-size: 120px;
    font-weight: 700;
    color: #609041;
    margin: 0;
    letter-spacing: 2px;
}

.not-found-message {
    font-size: 20px;
    font-weight: 450;
    color: #333;
    margin: 20px 0;
    line-height: 1.5;
}

.go-home-button {
    font-family: 'Poppins', sans-serif;
    background-color: #609041;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 450;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
}

.go-home-button:hover {
    background-color: #4c7a34;
    transform: translateY(-2px);
}

.go-home-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(96, 144, 65, 0.4);
}

.not-found-content>* {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .not-found-container {
        padding: 20px;
    }

    .not-found-content {
        padding: 30px 20px;
        width: 90%;
        max-width: none;
    }

    .not-found-title {
        font-size: 80px;
        letter-spacing: 1px;
    }

    .not-found-message {
        font-size: 18px;
    }

    .go-home-button {
        padding: 8px 20px;
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .not-found-container {
        padding: 40px;
    }

    .not-found-content {
        padding: 40px 30px;
        width: 80%;
        max-width: 600px;
    }

    .not-found-title {
        font-size: 100px;
        letter-spacing: 2px;
    }

    .not-found-message {
        font-size: 20px;
    }

    .go-home-button {
        padding: 15px 30px;
        font-size: 18px;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .not-found-container {
        padding: 50px;
    }

    .not-found-content {
        padding: 50px 40px;
        width: 70%;
        max-width: 800px;
    }

    .not-found-title {
        font-size: 120px;
        letter-spacing: 3px;
    }

    .not-found-message {
        font-size: 22px;
    }

    .go-home-button {
        padding: 15px 35px;
        font-size: 18px;
    }
}