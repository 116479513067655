.bio-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20%;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.bio-container.visible {
  opacity: 1;
}

.bio-background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('../resources/bannerBio.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.bio-content {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 5px !important;
  width: 50%;
  padding: 40px;
  color: white;
  text-align: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.bio-content p {
  font-family: "Poppins", sans-serif;
  font-size: 1.05rem !important;
  line-height: 1.8;
  color: #333;
  margin-bottom: 1.5rem !important;
  overflow-wrap: break-word;
}

@media screen and (max-width: 767px) {
  .bio-background-image {
    background: url('../resources/bannerBioVertical.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bio-container {
    padding: 1rem !important;
  }

  .bio-content {
    text-align: center;
    margin-top: 18rem;
    width: 85%;
    margin-right: 24px !important;
    padding: 1rem;
  }

  .bio-content p {
    font-size: 0.6rem !important;
    margin-bottom: 1.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .bio-container {
    padding: 1rem !important;
  }

  .bio-content {
    width: 60% !important;
  }

  .bio-content p {
    font-size: 0.7rem !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .bio-container {
    padding: 1rem;
  }

  .bio-content {
    width: 60% !important;
  }

  .bio-content p {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {

  .bio-content {
    width: 45% !important;
  }

  .bio-content p {
    font-size: 0.9rem !important;
  }
}