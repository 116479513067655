.navbar {
    background-color: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    padding: 0 20px;
    position: relative;
}

.navbar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}

.navbar-logo-image {
    width: 180px;
    height: auto;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.nav-item {
    height: 80px;
}

.nav-links {
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Montserrat', thin;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar {
        background-color: rgba(36, 34, 34, 0.8);
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: fixed;
        top: 80px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        justify-content: center;
        align-items: center;
    }

    .nav-menu.active {
        background-color: rgba(36, 34, 34, 0.8);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        font-size: 1.4rem;
    }

    .nav-links:hover {
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
    }

    .menu-icon {
        padding-bottom: 0.5rem !important;
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        font-size: 2.6rem;
        color: #fff;
        cursor: pointer;
    }

    .menu-icon i {
        font-size: 2.8rem;
    }
}