.brands-section {
    width: 100%;
    max-width: 1200px !important;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    overflow: hidden;
}

.carousel-title {
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin-top: 10px !important;
    position: relative;
}

.carousel-title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 40%;
    height: 3px;
    background-color: #609041;
    transform: translateX(-50%);
}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-arrow {
    cursor: pointer;
    color: #609041;
    font-size: 3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-arrow:hover {
    transform: scale(1.2);
}

.carousel-arrow.disabled {
    visibility: hidden;
}

.carousel-arrow::before {
    content: '';
    display: block;
    font-size: inherit;
}

.carousel-arrow.left::before {
    content: '<';
}

.carousel-arrow.right::before {
    content: '>';
}

.carousel-arrow.right {
    margin-left: 10px !important;
}

.carousel-arrow.left {
    margin-right: 10px !important;
}

.brands-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 1rem;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 1rem !important;
}

.brands-inner {
    display: flex;
    justify-content: flex-start;
    transition: transform 0.3s ease;
}

.brands-container img {
    padding: 0.5rem !important;
    width: 180px !important;
    height: auto !important;
    border-radius: 10px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.brands-container img:hover {
    transform: scale(1.1);
    opacity: 0.8;
}

/* Small screens (up to 480px) */
@media (max-width: 480px) {
    .carousel-arrow {
        font-size: 1.5rem !important;
        padding: 8px;
    }

    .brands-section {
        width: 100% !important;
        padding: 20px 0;
    }

    .carousel-title {
        font-size: 1rem !important;
    }

    .brands-container img {
        width: 85px !important;
        height: auto !important;
    }
}

/* Tablets & smaller laptops (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
    .carousel-arrow {
        font-size: 1.6rem !important;
        padding: 10px;
    }

    .brands-section {
        width: 95%;
    }

    .carousel-title {
        font-size: 1.5rem;
    }

    .brands-container img {
        width: 100px !important;
        height: auto !important;
    }
}

/* Medium to large laptops (1024px to 1439px) */
@media (min-width: 1024px) and (max-width: 1439px) {
    .carousel-arrow {
        font-size: 2rem;
        padding: 12px;
    }

    .brands-section {
        width: 90% !important;
    }

    .carousel-title {
        font-size: 1.75rem;
    }

    .brands-container img {
        width: 150px !important;
        height: auto !important;
    }
}

/* Desktop (1440px and above) */
@media (min-width: 1440px) {
    .carousel-arrow {
        font-size: 2.5rem;
        padding: 15px;
    }

    .brands-section {
        width: 83%;
    }

    .carousel-title {
        font-size: 2rem;
    }

    .brands-container img {
        width: 145px !important;
        height: auto !important;
    }
}