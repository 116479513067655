.about-brief-section {
  background-color: #ffffff;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 0;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  margin: 0 auto;
}

.about-brief-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 10px;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  height: auto;
  flex-wrap: wrap;
  margin: 0 auto;
}

.about-brief-text {
  flex: 1;
  text-align: left;
  margin-right: 40px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-brief-text h2 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  position: relative;
}

.about-brief-text h2::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #609041;
}

.about-brief-text p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.about-brief-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
}

.about-brief-left,
.about-brief-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 48%;
  position: relative;
}

.about-brief-left img {
  position: relative;
  top: 10px;
}

.about-brief-right img {
  position: relative;
  top: -10px;
}

.about-brief-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.about-brief-image:hover {
  transform: scale(1.1);
}

.about-brief-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  position: relative;
}

.about-brief-right img {
  position: relative;
}

.read-more-btn {
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
  background-color: #609041;
  font-weight: 600;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.read-more-btn:hover {
  background-color: #619041;
  color: #000000;
  border-color: #619041;
}

.read-more-btn:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .about-brief-content {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .about-brief-text {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-brief-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
  }

  .about-brief-left,
  .about-brief-right {
    width: 48%;
    display: flex;
    justify-content: center;
  }

  .about-brief-image {
    width: 100%;
    height: 480px;
    object-fit: cover;
    border-radius: 10px;
  }

  .about-brief-text h2 {
    font-size: 2rem;
  }

  .about-brief-text p {
    font-size: 1rem;
  }

  .read-more-btn {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 500px) {
  .about-brief-section {
    padding: 20px;
  }

  .about-brief-content {
    flex-direction: column;
    padding: 10px;
    width: 100%;
  }

  .about-brief-text {
    width: 100%;
    margin-bottom: 15px;
  }

  .about-brief-images {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
  }

  .about-brief-left,
  .about-brief-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
  }

  .about-brief-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
  }

  .about-brief-text h2 {
    font-size: 1.8rem;
  }

  .about-brief-text p {
    font-size: 0.9rem;
  }

  .read-more-btn {
    padding: 6px 12px;
  }
}

@media screen and (max-width: 375px) {
  .about-brief-images {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .about-brief-left,
  .about-brief-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
    flex-basis: 48%;
  }

  .about-brief-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .read-more-btn {
    padding: 6px 12px;
  }
}

@media screen and (max-width: 320px) {
  .about-brief-images {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .about-brief-left,
  .about-brief-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
    flex-basis: 48%;
  }

  .about-brief-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .read-more-btn {
    padding: 6px 12px;
  }
}