.contact-form-container {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2.5rem !important;
  font-family: "Poppins", sans-serif;
}

.contact-form-content {
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form-heading {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  color: #2d2d2d;
  margin-bottom: 40px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.contact-form-input {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 100%;
  background-color: #f9f9f9;
  color: #333;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.contact-form-textarea {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 100%;
  height: 160px;
  background-color: #f9f9f9;
  color: #333;
  resize: vertical;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.contact-form-button {
  padding: 15px;
  font-size: 18px;
  color: #ffffff;
  background-color: #609041;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Poppins", sans-serif;
}

.contact-form-button:hover {
  background-color: #4b7c32;
}

@media screen and (max-width: 768px) {
  .contact-form-content {
    margin-top: 120px;
  }

  .contact-form-heading {
    font-size: 28px;
  }

  .contact-form-row {
    flex-direction: column;
  }

  .contact-form-input {
    font-size: 14px;
  }

  .contact-form-button {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .contact-form-content {
    margin-top: 160px;
    padding: 20px;
  }

  .contact-form-heading {
    font-size: 28px;
  }

  .contact-form-row {
    flex-direction: column;
  }

  .contact-form-input {
    font-size: 14px;
  }

  .contact-form-button {
    font-size: 16px;
  }
}