.scroll-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    border-color: white;
    color: white;
    font-size: 20px;
}

.scroll-up.show {
    opacity: 1;
}

.scroll-up.hide {
    opacity: 0;
}