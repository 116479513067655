.link-bio-container {
  background-color: #ecf0f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  padding-top: 40px;
  box-sizing: border-box;
}

.link-bio-logo-container {
  margin-bottom: 20px;
}

.link-bio-logo {
  width: 160px;
  height: auto;
}

.banners-route-container {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.link-bio-banners {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.link-bio-banner {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.route-section {
  width: 100%;
  margin-bottom: 20px;
}

.route-box-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  margin: 0 auto;
  align-items: center;
}

.route-box {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #ffffff;
  background-image: linear-gradient(-90deg, #eaedf3 20%, transparent 10%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #333;
  font-size: 1rem !important;
  text-decoration: none;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  width: 100%;
  max-width: 420px;
}

.route-book-icon,
.route-link-icon {
  font-size: 1.5rem;
  color: #333;
  transition: transform 0.3s ease;
  margin-left: auto;
  display: inline-block;
}

.link-bio-social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.link-bio-social-icon {
  width: 65px;
  height: auto;
  cursor: pointer;
  object-fit: contain;
}