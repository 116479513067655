.spec-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5%;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.spec-container.visible {
  opacity: 1;
}

.spec-title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 2rem !important;
  font-weight: 600;
  margin-bottom: 20px;
}

.spec-background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('../resources/bannerPalestras.webp');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.spec-content {
  width: 40%;
  padding: 20px;
  color: white;
  text-align: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.spec-content p {
  font-family: "Poppins", sans-serif;
  font-size: 0.96rem !important;
  line-height: 1.8;
  color: #ffffff;
  margin-bottom: 1.5rem !important;
  overflow-wrap: break-word;
}

@media screen and (max-width: 767px) {
  .spec-content {
    text-align: center;
    margin-top: 2rem;
    width: 100%;
    padding: 1.5rem;
  }

  .spec-title {
    margin-top: 20px;
    font-size: 0.9rem !important;
  }

  .spec-content p {
    font-size: 0.6rem !important;
    margin-bottom: 1.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .spec-title {
    font-size: 1rem !important;
  }

  .spec-content p {
    font-size: 0.48rem !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .spec-title {
    font-size: 1.2rem !important;
  }

  .spec-content p {
    font-size: 0.56rem !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .spec-title {
    font-size: 1.8rem !important;
  }

  .spec-content p {
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 1440px) {
  .spec-title {
    font-size: 2.5rem;
  }

  .spec-content p {
    font-size: 1.6rem;
  }
}