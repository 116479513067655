.blog-highlights-wrapper {
  width: 100%;
  background-color: #ffffff !important; 
  padding: 0;
  box-sizing: border-box;
}

.blog-highlights-container {
  max-width: 1200px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.blog-highlights-container h2, p, a{
  font-family: "Poppins", sans-serif;
}

.blog-highlights-title {
  padding: 2rem !important;
  font-family: "Poppins", sans-serif;
  font-weight: 700px !important;
  font-size: 3rem;
  margin-bottom: 70px;
  color: #000000;
  text-align: center;
  position: relative;
  word-wrap: break-word;
}

.blog-highlights-title::after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #609041;
}

.blog-highlights-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 1130px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.blog-highlight-card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: auto;
}

.blog-highlight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.blog-highlight-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.highlight-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-image {
  background-color: #ffffff44;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #555;
  font-size: 1.4rem;
}

.blog-highlight-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlight-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.highlight-excerpt {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.read-more-button {
  align-self: flex-start;
  background-color: #609041;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.read-more-button:hover {
  background-color: #609041;
}

/* Mobile S (max-width = 370px) */
@media (max-width: 370px) {
  .blog-highlights-container {
    width: 93%;
  }

  .blog-highlights-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    width: 100%;
  }

  .blog-highlight-card {
    height: auto;
  }

  .blog-highlight-image {
    height: 150px;
  }

  .highlight-title {
    font-size: 1.2rem;
  }

  .highlight-excerpt {
    font-size: 0.9rem;
  }

  .read-more-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

/* Mobile M (min-width: 371px and max-width = 420px) */
@media (min-width: 371px) and (max-width: 420px) {
  .blog-highlights-container {
    width: 85%;
  }

  .blog-highlights-title {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    width: 100%;
  }

  .blog-highlight-card {
    height: auto;
  }

  .blog-highlight-image {
    height: 180px;
  }

  .highlight-title {
    font-size: 1.3rem;
  }

  .highlight-excerpt {
    font-size: 1rem;
  }

  .read-more-button {
    padding: 6px 12px;
    font-size: 1rem;
  }
}

/* Mobile L (min-width: 421px and max-width = 760px) */
@media (min-width: 421px) and (max-width: 760px) {
  .blog-highlights-container {
    width: 95%;
  }

  .blog-highlights-title {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .blog-highlight-card {
    height: auto;
  }

  .blog-highlight-image {
    height: 180px;
  }

  .highlight-title {
    font-size: 1.4rem;
  }

  .highlight-excerpt {
    font-size: 1rem;
  }

  .read-more-button {
    padding: 7px 14px;
    font-size: 1rem;
  }
}

/* Tablet (min-width: 761px and max-width: 1020px) */
@media (min-width: 761px) and (max-width: 1020px) {
  .blog-highlights-container {
    width: 100%;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Laptop (min-width: 1021px and max-width: 1430px) */
@media (min-width: 1021px) and (max-width: 1430px) {
  .blog-highlights-container {
    width: 95%;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Laptop L (min-width: 1431px and max-width: 1900px) */
@media (min-width: 1431px) and (max-width: 1900px) {
  .blog-highlights-container {
    width: 81%;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* 4K+ Display (min-width: 1931px) */
@media (min-width: 1931px) {
  .blog-highlights-container {
    width: 100%;
    margin: 0 auto;
  }

  .blog-highlights-title {
    font-size: 3.5rem;
    margin-bottom: 80px;
  }

  .blog-highlights-grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;
  }

  .blog-highlight-card {
    height: auto;
  }

  .blog-highlight-image {
    height: 220px;
  }

  .highlight-title {
    font-size: 1.7rem;
  }

  .highlight-excerpt {
    font-size: 1.1rem;
  }

  .read-more-button {
    padding: 10px 20px;
    font-size: 1.1rem;
  }
}
