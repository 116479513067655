.product-shop-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    flex-wrap: wrap;
    height: 100%;
}

.products-container {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding-left: 20px;
    margin-top: 0;
    height: 100%;
    position: relative;
    min-height: 300px;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.ball-prod {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.shop-menu {
    background-color: #f2f2f2;
    width: 25%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.shop-menu h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.product-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
}

.product-title::after {
    content: "";
    display: block;
    width: 50%;
    margin: 0 auto;
    height: 2px;
    background-color: #609041;
}

.filter-option {
    margin-bottom: 20px;
}

.filter-option label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.filter-option input[type="range"] {
    width: 100%;
    height: 8px;
    background: #ddd;
    border-radius: 5px;
    appearance: none;
    outline: none;
    transition: background 0.3s ease;
}

.filter-option input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #609041;
    cursor: pointer;
    transition: background 0.3s ease;
}

.filter-option input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #609041;
    cursor: pointer;
}

.filter-option input[type="range"]:hover {
    background: #bbb;
}

.filter-option input[type="range"]:active::-webkit-slider-thumb {
    background: #4a7734;
}

.filter-option input[type="range"]:focus {
    outline: none;
}

.filter-option span {
    display: block;
    margin-top: 8px;
    color: #333;
}

.filter-option input[type="text"],
.filter-option input[type="number"],
.filter-option select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    transition: border 0.3s ease;
}

.filter-option input[type="text"]:focus,
.filter-option input[type="number"]:focus,
.filter-option select:focus {
    border-color: #609041;
    outline: none;
}

.filter-option button {
    width: 100%;
    padding: 10px;
    background-color: #609041;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.filter-option button:hover {
    background-color: #4a7734;
    transform: scale(1.05);
}

.product-card {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.product-photo {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
}

.product-info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.product-price {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    margin: 10px 0;
}

.btn-comprar {
    display: inline-block;
    padding: 10px 20px;
    background-color: #609041;
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    font-weight: 450;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.product-description {
    padding: 0 1rem 4rem 1rem;
    font-size: 1rem !important;
    font-weight: 300;
}

.btn-comprar:hover {
    background-color: #4a7734;
    transform: translateX(-50%) scale(1.05);
}

.product-header {
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    font-weight: bold;
}

.product-header a {
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.product-header a:hover {
    color: #609041;
}

@media (max-width: 430px) {
    .product-shop-container {
        flex-direction: column;
        align-items: center;
    }

    .shop-menu {
        width: 100%;
        margin-bottom: 30px;
    }

    .products-container {
        width: 100%;
        padding: 0;
        height: auto;
        padding-bottom: 50px;
        position: relative;
        min-height: 300px;
    }

    .loading-spinner {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        z-index: 10;
        margin-bottom: 30px;
    }
}