.sinopsis-container {
    background-color: #f5f0e1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
}

.sinopsis-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
}

.sinopsis-image {
    flex: 1;
    padding-right: 20px;
}

.book-image {
    width: 100%;
    max-width: 470px;
    height: auto;
    border-radius: 8px;
}

.sinopsis-text {
    flex: 2;
    max-width: 600px;
}

.sinopsis-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    line-height: 1;
    font-weight: 600;
    color: #333;
}

.sinopsis-content p {
    font-size: 1rem !important;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

.disclaimer-text {
    font-size: 0.7rem;
    font-weight: 300;
    color: #444;
    margin-top: 20px;
    text-align: left;
}

.disclaimer-text p {
    margin-bottom: 10px;
}

.disclaimer-button {
    background-color: #B83A3A;
    color: white;
    font-size: .8rem !important;
    padding: 10px 60px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
    width: 50%;
    max-width: 350px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
}

.disclaimer-button:hover {
    background-color: #9e2b2b;
    transform: translateY(-2px);
}

.disclaimer-button:active {
    background-color: #861d1d;
    transform: translateY(2px);
}

/* Mobile (up to 425px) */
@media (min-width: 320px) and (max-width: 425px) {
    .sinopsis-container {
        padding: 30px 20px;
    }

    .sinopsis-title {
        font-size: 1.6rem !important;
    }

    .sinopsis-content p {
        font-size: 0.9rem !important;
    }

    .sinopsis-content {
        flex-direction: column;
        align-items: center;
    }

    .sinopsis-image {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .disclaimer-button {
        font-size: 1rem;
        max-width: 280px;
    }

    .disclaimer-text {
        font-size: 0.8rem;
        margin-top: 10px;
    }
}

.hero-button-sinopsis {
    position: relative;
    display: inline-block;
    padding: 9px 70px;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-decoration: none;
    color: #333;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #B83A3A;
    ;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #B83A3A;
    ;
}

.hero-button-sinopsis:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #B83A3A;
}

.hero-button-sinopsis:active {
    transform: scale(0.9);
}

/* Tablet (up to 768px) */
@media (min-width: 768px) and (max-width: 1023px) {
    .sinopsis-title {
        font-size: 2.2rem !important;
    }

    .sinopsis-content p {
        font-size: 1rem !important;
    }

    .disclaimer-button {
        font-size: 1.1rem;
        max-width: 320px;
    }

    .disclaimer-text {
        font-size: 0.9rem;
    }
}

/* Laptop (up to 1024px) */
@media (min-width: 1024px) and (max-width: 1439px) {
    .sinopsis-title {
        font-size: 2.3rem !important;
    }

    .sinopsis-content p {
        font-size: 1.2rem !important;
    }

    .disclaimer-button {
        font-size: 1.2rem;
    }
}

/* Laptop Large (up to 1440px) */
@media (min-width: 1440px) and (max-width: 1441px) {
    .sinopsis-title {
        font-size: 3.1rem !important;
    }

    .sinopsis-content p {
        font-size: 1.4rem !important;
    }

    .disclaimer-button {
        font-size: 1.3rem;
    }
}

/* Desktop and Larger Screens (above 1440px) */
@media (min-width: 1442px) {
    .sinopsis-title {
        font-size: 3.2rem;
    }

    .sinopsis-content p {
        font-size: 1.4rem;
    }

    .disclaimer-button {
        font-size: 1.4rem;
    }
}