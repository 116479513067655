    .hero-blog {
      background: black;
    }

    .hero-blog-container {
      background-image: url('../resources/bannerBlog.webp');
      background-color: white;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 50vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      object-fit: contain;
    }

    .hero-blog-text {
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .hero-blog-text h1 {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 2px;
      z-index: 1;
    }

    .hero-blog-text .line {
      width: 100%;
      height: 4px;
      background-color: #619041;
      margin-top: 10px;
    }

    .hero-blog-btns {
      margin-top: 800px;
      z-index: 1;
    }

    .hero-blog-btns .btn {
      margin: 6px;
    }

    .fa-play-circle {
      margin-left: 4px;
    }

    @media screen and (max-width: 768px) {
      .hero-blog-container {
        background-image: url('../resources/bannerBlogVertical.webp');
        height: 30vh;
        background-position: center top;
        padding-top: 50px;
      }

      .hero-blog-container>h1 {
        font-size: 50px;
        margin-top: -100px;
      }

      .hero-blog-container>p {
        font-size: 30px;
      }

      .btn-mobile {
        display: block;
        text-decoration: none;
      }

      .btn {
        width: 100%;
      }
    }
