.gallery-wrapper {
  padding: 200px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f4f0;
  background-image:
    linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%);
  background-size: 8px 8px;
  background-position: 0 0, -4px -4px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1170px;
  width: 100%;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 8px;
  cursor: pointer; /* Makes the image clickable */
}

.gallery-item:hover .gallery-img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(96, 144, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.description {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 0 10px;
  max-width: 80%;
}

/* Lightbox Styles */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.lightbox-img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
}

.lightbox-description {
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
}

.lightbox:hover .lightbox-description {
  display: block;
}