.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61904158;
}

.login-page {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.login-page h1 {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
}

.error {
    color: #e74c3c;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.cooldown-message {
    color: #856404;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.cooldown-message span {
    font-weight: bold;
    color: #d39e00;
}

.login-page input {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background-color: #fafafa;
    transition: border-color 0.3s ease;
}

.login-page input:focus {
    border-color: #609041;
    outline: none;
}

.login-page button {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 12px;
    background-color: #609041;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.login-page button:hover {
    background-color: #507033;
}

.form-footer {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.form-footer a {
    color: #609041;
    text-decoration: none;
    font-weight: 600;
}

.form-footer a:hover {
    text-decoration: underline;
}

@media (max-width: 500px) {
    .login-page {
        padding: 30px;
        width: 90%;
    }

    .login-page h1 {
        font-size: 24px;
    }

    .login-page input {
        font-size: 14px;
        padding: 10px;
    }

    .login-page button {
        font-size: 14px;
    }
}