.nucleos-container {
  background-color: #f5f0e1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.nucleos-content {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(1, 1, 1, 0.4);
  padding: 3rem !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1240px;
  padding: 2rem;
  gap: 40px;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.column-header {
  display: inline-flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.column-header i {
  font-size: 2.5rem;
  color: #B83A3A;
}

.column-title {
  font-size: 2.4rem;
  font-weight: bold;
  color: #333;
  line-height: 1;
}

.column-title::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #B83A3A;
  bottom: 0;
  left: 0;
}

.column p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}

/* Mobile (up to 425px) */
@media (min-width: 320px) and (max-width: 425px) {
  .nucleos-container {
    padding: 30px 20px;
  }

  .column-title {
    font-size: 1.4rem !important;
  }

  .column p {
    font-size: 0.9rem !important;
  }

  .nucleos-content {
    flex-direction: column;
    gap: 30px;
  }
}

/* Tablet (up to 768px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .column-title {
    font-size: 1.8rem !important;
  }

  .column p {
    font-size: 1rem !important;
  }
}

/* Laptop (up to 1024px) */
@media (min-width: 1024px) and (max-width: 1439px) {
  .column-title {
    font-size: 1.9rem !important;
  }

  .column p {
    font-size: 1.1rem !important;
  }
}

/* Laptop Large (up to 1440px) */
@media (min-width: 1440px) and (max-width: 1441px) {
  .column-title {
    font-size: 2.5rem !important;
  }

  .column p {
    font-size: 1.3rem !important;
  }
}