.admin-container {
    font-family: "Poppins", sans-serif;
    display: flex;
    height: 100vh;
}

.sidebar {
    font-family: "Poppins", sans-serif;
    width: 20%;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

.sidebar h1 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin: 0;
    font-size: 24px;
    margin-bottom: 15px;
}

.sidebar button {
    font-family: "Poppins", sans-serif;
    background-color: #34495e;
    border: none;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.content select {
    font-family: "Poppins", sans-serif;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.sidebar button:hover {
    background-color: #1abc9c;
}

.content {
    font-family: "Poppins", sans-serif;
    width: 80%;
    padding: 20px;
    overflow-y: auto;
}

.content p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.sidebar h2 {
    color: #34495e;
}

.sidebar button {
    background-color: #34495e;
    color: white;
    padding: 7px 14px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar button:hover {
    background-color: #16a085;
}

.content button {
    margin-top: 5px;
    background-color: #34495e;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.content button:hover {
    background-color: #16a085;
}

.action-form {
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}

.action-form input,
.action-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sidebar textarea {
    height: 100px;
}

.news-list ul {
    list-style-type: none;
    padding: 0;
}

.news-list li {
    background-color: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-list ul {
    list-style-type: none;
    padding: 0;
}

.product-list li {
    background-color: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.content input {
    margin-top: 10px;
}

.return-list img {
    max-width: 100px;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .sidebar h1 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .admin-container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        overflow-y: auto;
        height: 100%;
        padding-bottom: 20px;
    }

    .content {
        width: 100%;
    }
}