.new-footer-container {
    background-color: #000000;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.new-footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto;
}

.new-footer-logo {
    margin-right: 50px;
}

.new-footer-logo-img {
    width: 180px;
    height: auto;
}

.new-footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.new-footer-social-icon {
    color: #fff;
    font-size: 30px;
    text-decoration: none;
}

.new-footer-social-icon:hover {
    color: #B83A3A;
    transition: 0.3s ease-out;
}

.new-copy-text {
    padding: 1rem;
    font-family: "Poppins", sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 0.217);
    width: 100%;
    box-sizing: border-box;
}

@media screen and (max-width: 820px) {
    .new-footer-content {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .new-footer-logo {
        margin-bottom: 20px;
    }

    .new-footer-social-icons {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .new-footer-logo-img {
        width: 100px;
    }

    .new-footer-social-icon {
        font-size: 28px;
    }
}