    .hero-prod-section {
        background: black;
    }

    .hero-prod-container {
        background-color: rgb(255, 255, 255);
        background-image: url('../resources/bannerProdutos.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }

    .hero-prod-btns {
        margin-top: 800px;
    }

    .hero-prod-btns .btn {
        margin: 6px;
    }

    @media (max-width: 424px) {
        .hero-prod-container {
            height: 40vh;
            background-image: url('../resources/bannerProdutosVertical.webp');
            background-position: center top;
            background-size: contain;
            padding-top: 50px;
        }

        .hero-prod-container>h1 {
            font-size: 50px;
            margin-top: -100px;
        }

        .hero-prod-container>p {
            font-size: 30px;
        }
    }

    @media (min-width: 425px) and (max-width: 767px) {
        .hero-prod-container {
            height: 50vh;
            background-image: url('../resources/bannerProdutosVertical.webp');
            background-position: center top;
            background-size: contain;
            padding-top: 50px;
        }

        .hero-prod-container>h1 {
            font-size: 50px;
            margin-top: -100px;
        }

        .hero-prod-container>p {
            font-size: 30px;
        }
    }