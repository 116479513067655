.video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    background-size: cover;
    padding: 5rem 2rem;
    text-align: center;
}

.video-content-wrapper {
    max-width: 1150px;
    width: 100%;
    border-radius: 15px;
}

.section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 2rem;
    text-transform: uppercase;
    cursor: default;
    position: relative;

    background-color: transparent;
    border-radius: 0;
    transition: transform 0.3s ease;
}

.section-title:hover {
    transform: scale(1.05);
}

.section-title .title-text {
    margin-right: 10px;
}

.play-button-wrapper {
    display: flex;
    align-items: center;
}

.play-button {
    padding-left: 1rem !important;
    font-size: 2rem;
    color: #609041;
    transition: color 0.3s ease;
}

.section-title:hover .play-button {
    color: #fff;
}

.section-title::after {
    content: " ";
    position: absolute;
    
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #609041;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.section-title:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.video-strip {
    margin-top: 2rem;
    position: relative;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
}

.video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 768px) {
    .title-text {
        font-size: 1.5rem !important;
    }

    .play-button,
    ::before {
        font-size: 1.5rem !important;
    }

    .video-section {
        padding: 4rem 2rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .video-arrow {
        font-size: 1rem;
    }

    .video-container {
        width: 100%;
        padding-bottom: 56.25%;
    }

    .video-player {
        object-fit: contain;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1439px) {
    .video-section {
        padding: 5rem 3rem;
    }

    .section-title {
        font-size: 2.2rem;
    }

    .video-container {
        width: 100%;
        padding-bottom: 56.25%;
    }

    .video-player {
        object-fit: contain;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1500px) {
    .video-section {
        padding: 6rem 4rem;
    }

    .section-title {
        font-size: 2.5rem;
    }

    .video-container {
        width: 100%;
        padding-bottom: 56.25%;
    }

    .video-player {
        object-fit: contain;
    }
}