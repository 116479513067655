.authority-section {
  background-color: #f0f4f0;
  background-image:
    linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%);
  background-size: 8px 8px;
  background-position: 0 0, -4px -4px;





  background-position: center;
  background-size: contain;
  padding: 50px 0;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem !important;
  box-sizing: border-box;
  text-align: center;
}

.authority-section h2 {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 700;
}

.authority-section .highlight {
  font-weight: bold;
  color: #609041;
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
}

.authority-section p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-bottom: 40px;
}

.authority-section a {
  text-decoration: none;
}

.number-content span {
  color: rgba(96, 144, 65, 1);
}

.numbers {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.number-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 350px;
  padding: 40px;
  box-sizing: border-box;
  flex: 1 1 22%;
  min-width: 200px;
}

.number-item .logo-container {
  font-size: 60px;
  margin-bottom: 20px;
}

.number-item .number-content {
  text-align: center;
}

.number-item .number-content h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.number-item .number {
  font-size: 40px;
  font-weight: bold;
  color: rgba(96, 144, 65, 1);
  font-family: "Poppins", sans-serif;
}

.number-item p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #777;
}

.instagram-box {
  background-color: white;
}

.instagram-box .social-logo {
  color: #e4405f;
}

.youtube-box {
  background-color: white;
}

.youtube-box .social-logo {
  color: #ff0000;
}

.tiktok-box {
  background-color: white;
}

.tiktok-box .social-logo {
  color: #69c9d0;
}

.facebook-box {
  background-color: rgb(255, 255, 255);
}

.facebook-box .social-logo {
  color: #1877f2;
}

@media screen and (max-width: 768px) {
  .social-logo {
    transform: scale(2) !important;
  }
  .numbers {
    flex-direction: column;
    align-items: center;
  }

  .number-item {
    width: 90%;
    min-width: unset;
  }

  .number-item h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .highlight {
    font-size: 22px;
  }

  .number-item .logo-container {
    transform: scale(1.);
    transition: transform 0.3s ease-in-out;
  }

  .number-item p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .numbers {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }

  .number-item {
    width: 45%;
    min-width: 220px;
  }

  .number-item h2 {
    font-size: 20px;
  }

  .highlight {
    font-size: 24px;
  }

  .social-logo {
    font-size: 35px;
  }

  .number-item p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .numbers {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  .number-item {
    width: 48%;
  }

  .number-item h2 {
    font-size: 22px;
  }

  .highlight {
    font-size: 28px;
  }

  .social-logo {
    font-size: 40px;
  }

  .number-item p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .numbers {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  .number-item {
    width: 48%;
  }

  .number-item h2 {
    font-size: 24px;
  }

  .highlight {
    font-size: 30px;
  }

  .social-logo {
    font-size: 45px;
  }

  .number-item p {
    font-size: 18px;
  }
}