.footer-container {
  background-color: #000000;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  height: 200px;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  text-align: left;
}

.footer-subscription-heading {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  color: white;
}

.footer-input-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

.footer-input-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.footer-input {
  padding: 12px 20px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #fff;
  font-size: 16px;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
}

.footer-button {
  font-family: "Poppins", sans-serif;
  padding: 12px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #609041;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: 0.3s ease;
}

.footer-button:hover {
  background-color: #619041c3;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  padding-bottom: 2rem;
  width: 50%;
}

.footer-social-icon {
  color: #fff;
  font-size: 30px;
  text-decoration: none;
}

.footer-social-icon:hover {
  color: #609041;
  transition: 0.3s ease-out;
}

.copy-text {
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  text-align: left;
  color: rgba(255, 255, 255, 0.217);
  position: absolute;
  bottom: 0;
  left: 2rem;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 820px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .footer-subscription {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-input-section {
    width: 100%;
  }

  .footer-input-row {
    flex-direction: column;
    width: 100%;
  }

  .footer-button {
    width: 100%;
  }

  .footer-social-icons {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .footer-input,
  .footer-button {
    width: 100%;
  }

  .footer-subscription-heading {
    font-size: 24px;
  }
}
