.blog-container {
    width: 80%;
    margin: 0 auto;
    padding: 40px !important;
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
}

.blog-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    width: 80%;
    max-width: 600px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 25px;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: #609041;
}

.blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.blog-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
}

.blog-card:hover {
    transform: translateY(-10px);
}

.blog-card-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #ddd;
}

.blog-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    height: 250px;
    box-sizing: border-box;
}

.blog-card-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    transition: color 0.3s ease;
}

.blog-card-title:hover {
    color: #609041;
}

.blog-card-excerpt {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    height: 80px;
    overflow: hidden;
}

.no-results {
    text-align: center;
    font-size: 18px;
    color: #888;
    padding: 20px;
}

.blog-post-detail {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: relative;
    font-family: "Poppins", sans-serif;
    line-height: 1.8;
}

.blog-post-title {
    margin-top: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.blog-post-date {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
    text-align: center;
}

.blog-post-images {
    margin-top: 30px;
    margin-bottom: 30px;
}

.blog-post-images img {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    object-fit: cover;
}

.blog-post-images img {
    height: 300px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  
  @keyframes ball-spin {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(0);
      opacity: 1;
    }
  }
  

.loading-placeholder {
    width: 100%;
    height: 200px;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}


.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #609041;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 768px) {
    .blog-container {
        width: 95%;
        padding: 15px;
    }

    .blog-title {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .search-container {
        flex-direction: column;
        align-items: center;
    }

    .search-input {
        width: 90%;
        margin-bottom: 15px;
    }

    .blog-list {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }

    .blog-card {
        margin: 0;
    }

    .blog-card-image img {
        height: 180px;
    }

    .blog-card-content {
        padding: 15px;
        text-align: left;
    }

    .blog-card-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .blog-card-excerpt {
        font-size: 14px;
        height: auto;
    }

    .no-results {
        font-size: 16px;
    }

    .blog-post-detail {
        padding: 20px;
    }

    .blog-post-title {
        font-size: 30px;
    }

    .blog-post-date {
        font-size: 12px;
    }

    .blog-post-images img {
        height: 200px;
    }

    .loading-placeholder {
        width: 100%;
        height: 150px;
    }

    .blog-post-content {
        font-size: 16px;
    }

    .back-to-list-button {
        font-size: 14px;
        padding: 8px 15px;
    }
}

.top-image,
.middle-image,
.bottom-image {
    height: 300px;
    object-fit: cover;
    width: 100%;
}

@media (max-width: 768px) {

    .top-image,
    .middle-image,
    .bottom-image {
        height: 250px;
    }
}

.blog-post-content {
    font-size: 18px;
    color: #333;
    line-height: 1.8;
}

@media (min-width: 1200px) {
    .blog-post-images img {
        height: 350px;
    }
}

.back-to-list-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    background-color: #609041;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
}

.back-to-list-button:hover {
    background-color: #609041;
}