.hero-section {
    background: url('../resources/background.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    min-height: 100vh;
}

.hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1160px !important;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    z-index: 2;
}

.hero-text {
    flex: 1;
    padding: 20px;
    max-width: 600px;
    color: #ffffff;
}

.hero-text h1 {
    font-weight: bolder;
    font-size: 3rem !important;
    margin-bottom: 20px;
    line-height: 1.2;
}

.hero-text p {
    font-size: 1.2rem !important;
    margin-bottom: 15px;
    opacity: 0.9;
}

.hero-image {
    flex: 1;
    text-align: center;
    padding: 20px;
    z-index: 2;
}

.hero-image img {
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.1);
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    max-height: 550px !important;
    object-fit: cover;
}

.hero-button {
    position: relative;
    display: inline-block;
    padding: 9px 70px;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-decoration: none;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #B83A3A;;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #B83A3A;;
}

.hero-button:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #B83A3A;
}

.hero-button:active {
    transform: scale(0.9);
}

@media (max-width: 768px) {
    .hero-section {
        background: url('../resources/background.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .hero-content {
        margin-top: 370px !important;
        flex-direction: column;
        align-items: center;
        padding: 15px !important;
    }

    .hero-text {
        max-width: 100%;
        text-align: center;
        padding: 15px !important;
    }

    .hero-text h1 {
        font-size: 1.6rem !important;
    }

    .hero-text p {
        font-size: 0.9rem !important;
    }

    .hero-image img {
        max-width: 100% !important;
        max-height: 300px !important;
    }
}

@media (min-width: 1023px) and (max-width: 1439px) {
    .hero-section {
        background-position: 55% center;
    }

    .hero-content {
        width: 80% !important;
    }

    .hero-text p {
        font-size: 1rem !important;
    }

    .hero-text h1 {
        font-size: 2.2rem !important;
    }

    .hero-image img {
        max-width: 80% !important;
    }
}

@media (min-width: 1440px) and (max-width: 1500px) {
    .hero-section {
        background-position: 40% center;
    }

    .hero-content {
        width: 85% !important;
    }

    .hero-text h1 {
        font-size: 2.8rem !important;
    }

    .hero-text p {
        font-size: 1.2rem !important;
    }

    .hero-image img {
        max-width: 70% !important;
    }
}