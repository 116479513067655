.about-bsv-section {
  background-color: #f0f4f0;
  background-image:
    linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%),
    linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%);
  background-size: 8px 8px;
  background-position: 0 0, -4px -4px;
  
  
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 0;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  margin: 0 auto;
}

.about-bsv-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  height: auto;
  flex-wrap: wrap;
  margin: 0 auto;
}

.about-bsv-text {
  flex: 1;
  text-align: left;
  margin-left: 40px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.about-bsv-text h2 {
  color: black;
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  position: relative;
}

.about-bsv-text h2::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #609041;
}

.about-bsv-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.about-bsv-images-bsv {
  margin-right: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
}

.about-bsv-left,
.about-bsv-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 48%;
  position: relative;
}

.about-bsv-left img {
  position: relative;
  top: 10px;
}

.about-bsv-right img {
  position: relative;
  top: -10px;
}

.about-bsv-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.about-bsv-image:hover {
  transform: scale(1.1);
}

.about-bsv-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  position: relative;
}

.about-bsv-right img {
  position: relative;
  top: 0;
}

.read-more-btn-bsv {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem !important;
  text-decoration: none;
  color: #fff;
  background-color: #609041;
  font-weight: 300 !important;
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.read-more-btn-bsv:hover {
  background-color: #506f32;
}

.read-more-btn-bsv:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .about-bsv-content {
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .about-bsv-text {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-bsv-images-bsv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-bottom: 40px;
  }

  .about-bsv-left,
  .about-bsv-right {
    width: 48%;
    display: flex;
    justify-content: center;
  }

  .about-bsv-image {
    width: 100%;
    height: 480px;
    object-fit: cover;
    border-radius: 10px;
  }

  .about-bsv-text h2 {
    font-size: 2rem;
  }

  .about-bsv-text p {
    font-size: 1rem;
  }

  .read-more-btn-bsv {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 500px) {
  .about-bsv-section {
    padding: 20px;
  }

  .about-bsv-content {
    flex-direction: column;
    padding: 10px;
    width: 100%;
  }

  .about-bsv-text {
    width: 100%;
    margin-bottom: 15px;
  }

  .about-bsv-images-bsv {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-bottom: 40px;
  }

  .about-bsv-left,
  .about-bsv-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
  }

  .about-bsv-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
  }

  .about-bsv-text h2 {
    font-size: 1.8rem;
  }

  .about-bsv-text p {
    font-size: 0.9rem;
  }

  .read-more-btn-bsv {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 375px) {
  .about-bsv-images-bsv {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .about-bsv-left,
  .about-bsv-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
    flex-basis: 48%;
  }

  .about-bsv-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .read-more-btn-bsv {
    padding: 8px 15px;
  }
}

@media screen and (max-width: 320px) {
  .about-bsv-images-bsv {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .about-bsv-left,
  .about-bsv-right {
    width: 48% !important;
    display: flex;
    justify-content: center;
    flex-basis: 48%;
  }

  .about-bsv-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .read-more-btn-bsv {
    padding: 8px 15px;
  }
}