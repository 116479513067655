.about-bio-section {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 40px 0;
    width: 100%;
    margin: 0 auto;
}

.about-bio-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    padding: 40px;
    width: 100%;
    max-width: 1300px;
    height: auto;
    flex-wrap: wrap;
    margin: 0 auto;
}

.about-bio-text {
    flex: 1;
    text-align: left;
    margin-right: 40px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-bio-text h2 {
    color: #333;
    font-size: 2.2rem;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
}

.about-bio-text h2::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 55%;
    height: 4px;
    background-color: #ca5521;
}

.about-bio-text p {
    font-size: 0.96rem;
    line-height: 1.5;
    margin-bottom: 20px;
    font-weight: 400;
    color: #444;
}

.about-bio-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
    height: 100%;
    flex-wrap: wrap;
}

.about-bio-image-left,
.about-bio-image-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 48%;
    position: relative;
}

.about-bio-image-left img {
    position: relative;
    top: 10px;
}

.about-bio-image-right img {
    position: relative;
    top: -10px;
}

.about-bio-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.about-bio-image:hover {
    transform: scale(1.1);
}

.about-bio-image-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .about-bio-content {
        flex-direction: column;
        padding: 20px;
        width: 90%;
    }

    .about-bio-text {
        width: 100%;
        margin-bottom: 20px;
    }

    .about-bio-images {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .about-bio-image-left,
    .about-bio-image-right {
        width: 47%;
        display: flex;
        justify-content: center;
    }

    .about-bio-image {
        width: 100%;
        height: 480px;
        object-fit: cover;
        border-radius: 10px;
    }

    .about-bio-text h2 {
        font-size: 2rem;
    }

    .about-bio-text p {
        font-size: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .about-bio-content {
        padding: 20px;
        width: 85%;
    }

    .about-bio-text {
        width: 100%;
        margin-bottom: 20px;
    }

    .about-bio-images {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        width: 100%;
        margin-top: 20px;
    }

    .about-bio-image-left,
    .about-bio-image-right {
        width: 48%;
        display: flex;
        justify-content: center;
    }

    .about-bio-image {
        width: 100%;
        height: 550px;
        object-fit: cover;
        border-radius: 10px;
    }

    .about-bio-text h2 {
        font-size: 2rem;
    }

    .about-bio-text p {
        font-size: .8rem;
    }
}