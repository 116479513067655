.hero-container {
  background-image: url('../resources/backgroundHome.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-btns {
  margin-top: 800px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 100vh;
    background-image: url('../resources/imgHomeVertical.webp');
    background-position: center top;
    padding-top: 50px;
  }

  .hero-container>h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container>p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}