.mg-payment-section-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.mg-content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1280px;
    padding: 2rem;
    border-radius: 10px;
    gap: 30px;
    align-items: stretch;
    justify-content: center;
    z-index: 1;
    text-align: center;
    min-height: 400px;
}

.mg-image-container {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;
}

.mg-book-mockup {
    border-radius: 10px !important;
    max-width: 400px !important;
    width: 110% !important;
    object-fit: contain;
    height: auto;
}

.mg-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.mg-section-title {
    text-align: center;
    font-size: 2.2rem !important;
    font-weight: bold;
    color: #333;
    line-height: 1.3;
}

.mg-section-description {
    font-size: 1.3rem !important;
    color: #333;
}

.mg-purchase-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.mg-product-price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mg-appealing-price {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.mg-price-text {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}

.mg-price-highlight {
    font-size: 1.8rem !important;
    font-weight: 700;
    color: #333;
}

.mg-price-highlight-price {
    font-size: 2.2rem !important;
    font-weight: 800;
    background: linear-gradient(90deg, #B83A3A, #ff6565);
    -webkit-background-clip: text;
    color: transparent;
}

.mg-appealing-price p {
    color: #333;
    padding: .1rem !important;
    font-size: 1.6rem !important;
    font-weight: 500;
}

.mg-payment-methods {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.mg-payment-icon {
    width: 40px;
    height: auto;
    transition: transform 0.3s ease;
}

.mg-payment-icon:hover {
    transform: scale(1.1);
}

.mg-product-desc {
    font-weight: 300;
    font-size: .9rem;
    text-align: center;
    padding-bottom: 2rem !important;
}

.button {
    --width: 300px;
    --height: 45px;
    --tooltip-height: 45px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-button: 18px;
    --button-color: #B83A3A;
    --tooltip-color: #fff;
    width: var(--width);
    height: var(--height);
    background: var(--button-color);
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    transition: background 0.3s;
}

.button::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: #555;
    font-size: 0.9rem;
    color: #fff;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
}

.button::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #555;
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.button::after,
.button::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-wrapper,
.text,
.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
}

.text {
    top: 0
}

.text,
.icon {
    transition: top 0.5s;
}

.icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    width: 24px;
    height: 24px;
}

.button:hover {
    background: #B83A3A;
}

.button:hover .text {
    top: -100%;
}

.button:hover .icon {
    top: 0;
}

.button:hover:before,
.button:hover:after {
    opacity: 1;
    visibility: visible;
}

.button:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.button:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}

@media (max-width: 768px) {
    .mg-appealing-price p {
        font-size: 1.2rem !important;
    }

    .mg-price-highlight,
    .mg-installments {
        font-size: 0.9rem !important;
    }

    .mg-price-text {
        padding: 1rem !important;
    }

    .mg-section-description {
        font-size: 1rem !important;
    }

    .mg-price-highlight-price {
        font-size: 1.2rem !important;
    }

    .mg-product-desc {
        font-size: .7rem !important;
    }

    .mg-content-wrapper {
        flex-direction: column;
        padding: 0 1rem 0rem 1rem;
        gap: 20px;
        width: 100%;
        align-items: center;
    }

    .mg-image-container {
        max-width: 250px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .mg-book-mockup {
        max-width: 250px !important;
        width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }

    .mg-text-container {
        text-align: center;
        gap: 15px;
    }

    .mg-appealing-price {
        margin-top: 15px;
    }

    .mg-price-text {
        font-size: 1.8rem;
    }

    .mg-product-price {
        font-size: 1.6rem;
        padding: 8px 16px;
    }

    .mg-payment-methods {
        gap: 10px;
    }

    .mg-section-title {
        font-size: 1.4rem !important;
    }

    .mg-product-desc {
        font-size: 0.9rem;
        padding: 15px;
        margin-top: 20px;
        text-align: center;
        order: 2;
    }
}

@media (min-width: 1023px) and (max-width: 1439px) {
    .mg-content-wrapper {
        flex-direction: row;
        padding: 2rem;
        gap: 30px;
    }

    .mg-image-container {
        max-width: 300px;
    }

    .mg-book-mockup {
        max-width: 400px;
        width: 100%;
    }

    .mg-text-container {
        text-align: left;
        gap: 25px;
    }

    .mg-section-title {
        font-size: 1.8rem;
    }

    .mg-section-description {
        font-size: 1rem;
    }

    .mg-product-price {
        font-size: 1.8rem;
    }

    .mg-payment-methods {
        gap: 12px;
    }

    .mg-product-desc {
        font-size: 1rem;
        padding: 15px;
        margin-top: 20px;
    }
}

@media (min-width: 1440px) {
    .mg-content-wrapper {
        flex-direction: row;
        width: 90%;
        gap: 40px;
        padding: 2.5rem;
    }

    .mg-image-container {
        max-width: 350px;
    }

    .mg-book-mockup {
        max-width: 300px;
        width: 100%;
    }

    .mg-text-container {
        flex: 1;
        text-align: left;
        gap: 30px;
    }
}