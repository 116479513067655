.faq-wrapper {
  background: url('../resources/backgroundFaq.webp');
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  padding: 50px 0;
}

.faq-container {
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-container h2 {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.faq-container span {
  font-family: "Poppins", sans-serif;
}

.show-all-button {
  font-family: "Poppins", sans-serif;
  display: block;
  background: none;
  color: #609041;
  border: none;
  padding: 8px 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
}

.show-all-button:hover {
  color: #61904195;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
}

.faq-answer {
  padding: 10px 0;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

.arrow {
  font-size: 1rem;
}

@media (max-width: 425px) {
  .faq-container {
    width: 100% !important;
  }

  .faq-container h2 {
    font-size: 1.5rem;
  }

  .faq-item {
    padding: 10px 0;
  }

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }

  .show-all-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .faq-container {
    width: 100% !important;
  }

  .faq-container h2 {
    font-size: 1.75rem;
  }

  .faq-item {
    padding: 12px 0;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 1rem;
  }

  .show-all-button {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .faq-container {
    width: 85% !important;
  }

  .faq-container h2 {
    font-size: 1.75rem;
  }

  .faq-item {
    padding: 14px 0;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 1rem;
  }

  .show-all-button {
    font-size: 1rem;
  }
}

@media (max-width: 1439px) {
  .faq-container {
    width: 95% !important;
  }

  .faq-container h2 {
    font-size: 1.8rem;
  }

  .faq-item {
    padding: 16px 0;
  }

  .faq-question {
    font-size: 1.2rem;
  }

  .faq-answer {
    font-size: 1.1rem;
  }

  .show-all-button {
    font-size: 1.1rem;
  }
}

@media (min-width: 1440px) {
  .faq-container {
    width: 88% !important;
  }

  .faq-container h2 {
    font-size: 2rem;
  }

  .faq-item {
    padding: 20px 0;
  }

  .faq-question {
    font-size: 1.2rem;
  }

  .faq-answer {
    font-size: 1.1rem;
  }

  .show-all-button {
    font-size: 1.2rem;
  }
}

@media (min-width: 2560px) {
  .faq-container {
    width: 100% !important;
  }

  .faq-container h2 {
    font-size: 2rem;
  }

  .faq-item {
    padding: 20px 0;
  }

  .faq-question {
    font-size: 1.2rem;
  }

  .faq-answer {
    font-size: 1.1rem;
  }

  .show-all-button {
    font-size: 1.2rem;
  }
}
