.post-container h1,
.post-container p {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.6;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    padding: 10px;
}

.modal-image {
    width: 100%;
    height: auto;
    max-width: 900px;
    max-height: 650px;
    object-fit: contain;
    border-radius: 8px;
    border: none;
}

.loading-spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #609041;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.post-container {
    padding: 3rem !important;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.post-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1d1d1d;
    margin-bottom: 20px;
    line-height: 1.4;
    text-align: center;
    width: 100%;
}

.post-content {
    font-size: 1.125rem;
    color: #555;
    line-height: 1.8;
    text-align: justify;
    width: 60%;
}

.post-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 35%;
    position: relative;
    margin-left: auto;
}

.image-container {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

.post-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    margin: 0;
}

.post-image:hover {
    transform: scale(1.05);
}


.post-image-first {
    height: 300px;
    padding-bottom: 0;
}

footer {
    background-color: #333;
    color: white;
    padding: 2rem 0;
    text-align: center;
}

footer a {
    color: white;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

footer a:hover {
    color: #609041;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .post-container {
        padding: 2rem;
        flex-direction: column;
    }

    .post-title {
        font-size: 2rem;
        text-align: center;
    }

    .post-content {
        font-size: 1rem;
        width: 100%;
    }

    .post-images {
        width: 100%;
    }

    .post-image-first {
        height: 250px;
    }

    .post-image {
        height: 200px;
    }
}